<template>
  <v-col :class="classField" v-if="field.type === 'FILE_UPLOAD'">
    <FileField :field="field" :showPreview="showPreview" :addFilesOnDetail="addFilesOnDetail" ref="fieldValidationForm"></FileField>
  </v-col>
  <v-col :class="classField" v-else-if="field.type === 'PHONE'">
    <PhoneField :field="field" ref="fieldValidationForm" @input="changeInput"></PhoneField>
  </v-col>
  <v-col :class="classField" v-else-if="field.type === 'BENEFICIARY'">
    <BeneficiaryField :field="field" ref="fieldValidationForm" @input="changeInput"></BeneficiaryField>
  </v-col>
  <v-col :class="classField" v-else-if="field.type === 'BOOLEAN'">
    <BooleanField :field="field" ref="fieldValidationForm"></BooleanField>
  </v-col>
  <v-col :class="classField" v-else-if="field.type === 'CURRENCY'">
    <CurrencyField :field="field" ref="fieldValidationForm" :countryCode="countryCode" @input="changeInput"></CurrencyField>
  </v-col>
  <v-col :class="classField" v-else-if="field.type === 'SELECT'">
    <SelectField :field="field" ref="fieldValidationForm"></SelectField>
  </v-col>
  <v-col :class="classField" v-else-if="field.type === 'EMAIL'">
    <EmailField :field="field" ref="fieldValidationForm" @input="changeInput"></EmailField>
  </v-col>
  <v-col :class="classField" v-else-if="field.type === 'SEARCH_BENEFICIARY_WITH_EXTERNAL_BENEFITS'">
    <SearchBeneficiaryExternalBenefits ref="fieldValidationForm" @input="changeInput" />
  </v-col>
  <v-col :class="classField" v-else-if="field.type === 'SECTION_TITLE' || field.type === 'DASHED_DIVIDER' ">
  </v-col>
  <v-col v-else :class="classField">
    <v-row>
      <v-col class="col-12 pb-0">
        <div v-if="!!$slots.title">
          <slot name="title"></slot>
        </div>
        <span v-else class="text-subtitle-1 osg-black--text">{{ field.label }}</span>
      </v-col>

      <v-col cols="12" :class="{'py-0': !field.required && field.label}" >
        <span class="text-overline" v-if="!field.required && field.label"> {{ $t('addAuthorization.fields.optional') }} </span>
      </v-col>
    </v-row>
    <v-row no-gutters v-if="field.description">
      <v-col class="pb-1">
        <span class="text-body-2 osg-bloack--text">{{ field.description }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <template v-if="field.type === 'TEXT'">
          <v-text-field
            ref="fieldValidationForm"
            hide-details="auto"
            outlined
            dense
            :rules="fieldRules"
            :prefix="field.prefix"
            :suffix="field.suffix"
            @input="changeInput"
            :placeholder="field.placeholder"
            :hint="field.helpText"
            persistent-hint
          ></v-text-field>
        </template>
        <template v-else-if="field.type === 'INTEGER'">
          <v-text-field
            ref="fieldValidationForm"
            :rules="[...fieldRules, rules.zero, (value) => rules.maxValue(value, field.max)]"
            type="number"
            outlined
            dense
            :prefix="field.prefix"
            :suffix="field.suffix"
            v-osg-pattern=/^[0-9]+$/
            v-model="fieldValue"
            @input="changeInput"
            hide-details="auto"
            :placeholder="field.placeholder"
            :hint="field.helpText"
            persistent-hint
          ></v-text-field>
        </template>
        <template v-else-if="field.type === 'DECIMAL'">
          <v-text-field
            ref="fieldValidationForm"
            :rules="[...fieldRules]"
            type="text"
            :readonly="isReadOnly"
            outlined
            dense
            v-osg-pattern=getDecimalPattern()
            v-model="fieldValue"
            :prefix="field.prefix"
            :suffix="field.suffix"
            @input="changeInput"
            hide-details="auto"
            :placeholder="field.placeholder"
            :hint="field.helpText"
            :max="field.max"
            persistent-hint
            :error="maxCustomError.errror"
            :error-messages="maxCustomError.message"
          ></v-text-field>
        </template>
        <template v-else-if="field.type === 'PERCENTAGE'">
          <v-text-field
            ref="fieldValidationForm"
            :rules="[...fieldRules, rules.zero, value => rules.maxValue(value, 100)]"
            type="text"
            :prefix="field.prefix"
            outlined
            dense
            :suffix="field.suffix"
            v-osg-pattern=/^[,.0-9]+$/
            v-model="fieldValue"
            @input="changeInput"
            @blur="getFixedValue(0)"
            hide-details="auto"
            :placeholder="field.placeholder"
            :hint="field.helpText"
            persistent-hint
          ></v-text-field>
        </template>
        <template v-else-if="field.type === 'DATE'">
          <v-text-field
            ref="fieldValidationForm"
            append-outer-icon="event"
            v-osg-date
            outlined
            dense
            type = "text"
            :prefix="field.prefix"
            :suffix="field.suffix"
            inputmode = "numeric"
            hide-details="auto"
            :rules="[...fieldRulesDate]"
            @input="changeInput"
            :placeholder="field.placeholder"
            :hint="field.helpText"
            persistent-hint
          ></v-text-field>
        </template>
        <template v-else-if="field.type === 'SPONSOR_PROVIDERS_DROPDOWN'">
          <SponsorProvidersDropDown
            :field="field"
            :sponsor-slug="sponsorSlug"
            :placeholder="field.placeholder"
            ref="fieldValidationForm"
            @input="changeInput"/>
        </template>
        <template v-else-if="field.type === 'DROPDOWN'">
          <v-select
            :rules="fieldRules"
            ref="fieldValidationForm"
            hide-details="auto"
            dense
            outlined
            v-model="fieldValue"
            :placeholder="field.placeholder"
            item-text="label"
            @change="changeInput"
            @input="changeInput"
            item-value="key"
            :items="field.options"
            :hint="field.helpText"
            persistent-hint
          ></v-select>
        </template>
        <template v-else-if="field.type === 'DIAGNOSES'">
          <DiagnosesFinder
            :value="recoverFieldArrayValue"
            :search-label="$t('addAuthorization.diagnoses.searchCustom', {label: field.label.toLowerCase() })"
            :custom-diagnosis-item-list-label="$t('addAuthorization.diagnoses.customDiagnosisItemListLabel')"
            :maximum-number-of-items="10"
            ref="fieldValidationForm"
            :is-allowed-custom-diagnoses="false"
            @input="changeInput">
            <template v-slot:no-data>
              <v-row dense class="osg-empty-drug-container align-content-center">
                <v-col class="text-center primary--text">
                  <div class="text-button text-none pb-2 pl-6">
                    <v-icon size="40">$osg-diagnoses-icon</v-icon>
                    {{ $t("addAuthorization.diagnoses.emptyStateCustom", {label: field.label.toLowerCase() }) }}
                  </div>
                </v-col>
              </v-row>
            </template>
          </DiagnosesFinder>
        </template>
        <template v-else-if="field.type === 'DIFFERENTIAL_DIAGNOSES'">
          <DiagnosesFinder
            :value="recoverFieldArrayValue"
            :search-label="$t('addAuthorization.diagnoses.searchCustom', {label: field.label.toLowerCase()})"
            :custom-diagnosis-item-list-label="$t('addAuthorization.diagnoses.customDiagnosisItemListLabel')"
            :maximum-number-of-items="10"
            ref="fieldValidationForm"
            :is-allowed-custom-diagnoses="false"
            @input="changeInput">
            <template v-slot:no-data>
              <v-row dense class="osg-empty-drug-container align-content-center">
                <v-col class="text-center primary--text">
                  <div class="text-button text-none pb-2 pl-6">
                    <v-icon size="40">$osg-diagnoses-icon</v-icon>
                    {{ $t("addAuthorization.diagnoses.emptyStateCustom", {label: field.label.toLowerCase() }) }}
                  </div>
                </v-col>
              </v-row>
            </template>
          </DiagnosesFinder>
        </template>
        <template v-else-if="field.type === 'DIAGNOSTIC_TEST_PRODUCT'">
          <ProductFinder
            :value="recoverFieldArrayValue"
            @input="changeInput"
            product-type-slug="diagnostic-test"
            :search-label="$t('addAuthorization.diagnosticTests.searchLabel')"
            :country-code="countryCode"
            :showPriceOfProduct="true"
            :currencyCode="currencyCode"
            ref="fieldValidationForm"
          >
            <template v-slot:no-data>
              <v-row dense class="osg-empty-drug-container align-content-center">
                <v-col class="text-center primary--text">
                  <div class="text-button text-none pb-2 pl-6">
                    <v-icon size="40">$osg-diagnostic-test-icon</v-icon>
                    {{ $t('addAuthorization.diagnosticTests.emptyState') }}
                  </div>
                </v-col>
              </v-row>
            </template>
          </ProductFinder>
        </template>
        <template v-else-if="field.type === 'LABORATORY_PRODUCT'">
          <ProductFinder
            :value="recoverFieldArrayValue"
            @input="changeInput"
            product-type-slug="laboratory"
            :search-label="$t('addAuthorization.laboratories.searchLabel')"
            :country-code="countryCode"
            :showPriceOfProduct="true"
            :currencyCode="currencyCode"
            ref="fieldValidationForm"
          >
            <template v-slot:no-data>
              <v-row dense class="osg-empty-drug-container align-content-center">
                <v-col class="text-center primary--text">
                  <div class="text-button text-none pb-2 pl-6">
                    <v-icon size="40">$osg-laboratory-icon</v-icon>
                    {{ $t('addAuthorization.laboratories.emptyState') }}
                  </div>
                </v-col>
              </v-row>
            </template>
          </ProductFinder>
        </template>
        <template v-else-if="field.type === 'MEDICAL_PROCEDURE_PRODUCT'">
          <ProductFinder
            :value="recoverFieldArrayValue"
            @input="changeInput"
            product-type-slug="medical-procedure"
            :search-label="$t('addAuthorization.medicalProcedure.searchLabel')"
            :country-code="countryCode"
            :showPriceOfProduct="false"
            :currencyCode="currencyCode"
            ref="fieldValidationForm"
          >
            <template v-slot:no-data>
              <v-row dense class="osg-empty-drug-container align-content-center">
                <v-col class="text-center primary--text">
                  <div class="text-button text-none pb-2 pl-6">
                    <v-icon size="40">$osg-drug-icon</v-icon>
                    {{ $t('addAuthorization.medicalProcedure.emptyState') }}
                  </div>
                </v-col>
              </v-row>
            </template>
          </ProductFinder>
        </template>
        <template v-else-if="field.type === 'DRUG_PRODUCT'">
          <ProductFinder
            :value="recoverFieldArrayValue"
            @input="changeInput"
            product-type-slug="drug"
            :search-label="$t('addAuthorization.drug.searchLabel')"
            :country-code="countryCode"
            :showCountOfProduct="true"
            :showPriceOfProduct="true"
            :currencyCode="currencyCode"
            ref="fieldValidationForm"
          >
            <template v-slot:no-data>
              <v-row dense class="osg-empty-drug-container align-content-center">
                <v-col class="text-center primary--text">
                  <div class="text-button text-none pb-2 pl-6">
                    <v-icon size="40">$osg-drug-icon</v-icon>
                    {{ $t('addAuthorization.drug.emptyState') }}
                  </div>
                </v-col>
              </v-row>
            </template>
          </ProductFinder>
        </template>
        <template v-else-if="field.type === 'TEXTAREA'">
          <v-textarea
            ref="fieldValidationForm"
            hide-details="auto"
            outlined
            dense
            :rules="[...fieldRules, (value) => rules.maxLength(value, field.max)]"
            :prefix="field.prefix"
            :suffix="field.suffix"
            @input="changeInput"
            :placeholder="field.placeholder"
            :hint="field.helpText"
            single-line
            persistent-hint
            :counter="field.max"
            auto-grow
            rows="10"
            row-height="10">
        </v-textarea>
        </template>
        <template v-else-if="customObjectJsonFields.includes(field.type)">
          <ObjectField
            :type="field.type"
            :countryCode="countryCode"
            :fields="getCustomObjectFields(field.type)"
            :value="recoverFieldObjectValue"
            @input="changeInput"
            ref="fieldValidationForm"
          >
          </ObjectField>
        </template>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>

import DiagnosesFinder from './custom-fields/DiagnosesFinder.vue';
import SponsorProvidersDropDown from './custom-fields/SponsorProvidersDropDown.vue';
import ProductFinder from './custom-fields/ProductFinder.vue';
import ObjectField from './custom-fields/ObjectField.vue';
import FileField from './field-types/File.vue'
import PhoneField from './field-types/Phone.vue'
import BeneficiaryField from './field-types/BeneficiaryField.vue'
import BooleanField from './field-types/Boolean.vue'
import CurrencyField from './field-types/Currency.vue'
import SelectField from './field-types/Select.vue'
import EmailField from './field-types/Email.vue'
import SearchBeneficiaryExternalBenefits from './custom-fields/beneficiary-external-benefits/SearchBeneficiaryExternalBenefits.vue'

import { config } from '@/plugins/env.config'
import { createNamespacedHelpers } from 'vuex'
const authorizationModule = createNamespacedHelpers('authorizationV2');

import {
  inputRules,
  customArrayJsonFields,
  customObjectJsonFields,
  customObjectJsonFieldConfiguration,
  fieldsWithAddButton
} from '@/utils'

export default {
  name: 'AuthorizationField',
  components: {
    DiagnosesFinder, ProductFinder, ObjectField,
    FileField, PhoneField, BeneficiaryField, BooleanField,
    CurrencyField, SelectField, EmailField, SponsorProvidersDropDown,
    SearchBeneficiaryExternalBenefits
  },
  props: {
    field:{
      type: Object,
      default: () => ({
        slug: null,
        type: null,
        label: null,
        showOrder: null,
        required: null,
        description: null,
        groupName: null,
        mimeTypes: null,
        fileLimit: null,
        options: null,
        prefix: null,
        suffix: null,
        placeholder: null,
        decimalPrecision: null,
        groupOrder: null,
        cssClass: 'col-12',
        min: null,
        max: null,
        defaultValue: null
      })
    },
    showPreview: {
      type: Boolean,
      default: true
    },
    addFilesOnDetail: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      rules: inputRules,
      fieldValue: null,
      config,
      customArrayJsonFields,
      customObjectJsonFields,
      customObjectJsonFieldConfiguration,
      fieldsWithAddButton,
      maxCustomError: {
        error: false,
        message: ''
      }
    }
  },
  mounted() {
    this.fieldValue = null

    if (this.field.defaultValue) {
     this.fieldValue = this.formattedAmount(this.field.defaultValue);
      this.changeInput(this.fieldValue)
    }
  },
  methods: {
    ...authorizationModule.mapActions(['setError', 'setCurrentCoverage',
    'setCurrentForm', 'setInput', 'resetAuthorizationState', 'removeInput']),
    validate() {
      if ((this.customArrayJsonFields.includes(this.field.type) || this.customObjectJsonFields.includes(this.field.type) )) {

        if(this.$refs.fieldValidationForm.customValidations){
          const customValidation = this.$refs.fieldValidationForm.customValidations();

          if(customValidation.error){
            this.setError({
              hasError: true,
              error: customValidation.message
            })

            return false;
          }
        }
        if (this.field.required && !this.$refs.fieldValidationForm?.validate()) {
          this.setError({
            hasError: true,
            error: this.$i18n.t('errors.inputValidations.customFields.'.concat(this.field.type).concat('.required'))
          })

          return false;
        }
      } else {
        if("SECTION_TITLE" === this.field.type || "DASHED_DIVIDER" === this.field.type) {
          return true;
        }
        if (!this.$refs.fieldValidationForm.validate(true)) {
          return false;
        }
      }

      return true;
    },
    changeInput(e) {
      let value = e;
      if (this.customArrayJsonFields.includes(this.field.type) || this.customObjectJsonFields.includes(this.field.type)) {
        value = JSON.stringify(value)
      }

      if (this.field.type === 'DECIMAL') {
        value = parseFloat(value.toString().replace(/,/g, ''));
        const valueParsed = parseFloat(value.toString().replace(/,/g, ''));
        value = valueParsed.toFixed(2);
      }

      this.$emit("input", value);
    },
    getDecimalPattern() {
      return new RegExp(`^[,.0-9]*\\.?([0-9]{0,${this.field.decimalPrecision}}?)?$`);
    },
    getFixedValue(precission) {
      if (this.fieldValue) {
        this.fieldValue = this.formattedAmount(this.fieldValue, precission)
      }
    },

    getCustomObjectFields(fieldType) {
      const configurationfield = this.customObjectJsonFieldConfiguration.find(field => field.type === fieldType)
      return configurationfield && configurationfield.fields || []
    },
    formattedAmount(amount) {
      return parseFloat(amount.toString().replace(/,/g, ''));
    },
    updateRemainderBeneficiaryInput() {
      const outstandingBalance = isNaN(this.outstandingBalance) ? this.outstandingBalance.replace(/,/g, '') : this.outstandingBalance;
      const sumAssured = isNaN(this.sumAssured) ? this.sumAssured.replace(/,/g, '') : this.sumAssured;
      const maxSumAssured = this.maxSumAssured;
      const outstandingBalanceParse = parseFloat(outstandingBalance);

      if (this.sponsorSlug == 'mx-findep') {
        const sumAssuredDefault = parseFloat(sumAssured)
        const substracRemainderForBeneficiary = sumAssuredDefault - outstandingBalanceParse;
        const remainderForBeneficiary = substracRemainderForBeneficiary > 0 ? substracRemainderForBeneficiary : 0.00;
        this.fieldValue = this.formattedAmount(remainderForBeneficiary).toFixed(2);
        this.changeInput(this.fieldValue);
      }

      if (this.sponsorSlug == 'mx-aef') {
        const maxSumAssuredDefault = parseFloat(maxSumAssured)
        const substracRemainderForBeneficiary = maxSumAssuredDefault - outstandingBalanceParse;
        const remainderForBeneficiary = substracRemainderForBeneficiary > 0 ? parseFloat(substracRemainderForBeneficiary).toFixed(2) : 0.00;
        this.fieldValue = this.formattedAmount(remainderForBeneficiary).toFixed(2);
        this.changeInput(this.fieldValue);
      }
    },
  },
  computed: {
    ...authorizationModule.mapGetters(['currentForm', 'currentCoverage',
    'authorizationFormsAndSteps', 'inputs', 'countryCode', 'currencyCode', 'sponsorSlug', 'currentAuthorizationFormCoverage']),
    fieldRules() {
      const fieldRulesArray = [];

      if (this.field.required) {
        fieldRulesArray.push(this.rules.required)
      }

      if (this.field.type === 'DECIMAL' || this.field.type === 'CURRENCY') {
        fieldRulesArray.push(this.rules.isValidDecimalFormat);
      }

      if(this.field.min === 0 && this.field.max > 0){
        fieldRulesArray.push(this.rules.maxAndMinWithZeroValue(this.field.min, this.field.max))
      } else {
        if (this.field.max) {
          fieldRulesArray.push(this.rules.maxInputValue(this.field.max))
        }

        if (this.field.min) {
          fieldRulesArray.push(this.rules.minInputValue(this.field.min))
        }
      }

      return fieldRulesArray;
    },
    fieldRulesDate() {
      let fieldRulesArray = []

      if(this.currentAuthorizationFormCoverage === 'SECOND_ASSESSMENT'){
        fieldRulesArray = [this.rules.dateAfter]
      } else {
        fieldRulesArray = [this.rules.date]
      }

      if (this.field.slug === 'cycle_expiration_date') {
        fieldRulesArray = [this.rules.dateFormat]
      }

      if (this.field.required) {
        fieldRulesArray.push(this.rules.required)
      }

      if (this.field.slug === 'insurance_claim_date') {
        fieldRulesArray.push(this.rules.dateBefore(this.insuranceContractDate, this.insuranceClaimDate))
      }

      if (this.field.slug === 'unemployment_start_date') {
        fieldRulesArray.push(this.rules.dateBefore(this.insuranceContractDate, this.unemploymentStartDate))
      }

      return fieldRulesArray
    },

    classField() {
      return this.field.cssClass || 'col-12'
    },
    recoverFieldArrayValue() {
      const inputField = this.inputs.find(input => input.slug === this.field.slug);
      return inputField && JSON.parse(inputField.value) || []
    },
    recoverFieldObjectValue() {
      const inputField = this.inputs.find(input => input.slug === this.field.slug);
      return inputField && JSON.parse(inputField.value) || {}
    },
    insuranceContractDate() {
      const insuranceContractDate = this.inputs.find(input => input.slug === 'insurance_contract_date');
      return insuranceContractDate && insuranceContractDate.value || null
    },
    insuranceClaimDate() {
      const insuranceClaimDate = this.inputs.find(input => input.slug === 'insurance_claim_date');
      return insuranceClaimDate && insuranceClaimDate.value || null
    },
    unemploymentStartDate() {
      const unemploymentStartDate = this.inputs.find(input => input.slug === 'unemployment_start_date');
      return unemploymentStartDate && unemploymentStartDate.value || null
    },
    modules() {
      const modules = this.inputs.find(input => input.slug === 'modules')
      return modules && modules.value || 0
    },
    defaultValue() {
      return this.field.defaultValue
    },
    outstandingBalance() {
      const outstandingBalance = this.inputs.find(input => input.slug === 'outstanding_balance');
      return outstandingBalance && outstandingBalance.value || 0.00
    },
    sumAssured() {
      const sumAssured = this.inputs.find(input => input.slug === 'sum_assured');
      return sumAssured && sumAssured.value || 0.00
    },
    maxSumAssured() {
      const maxSumAssured = this.inputs.find(input => input.slug === 'max_sum_assured');
      return maxSumAssured && maxSumAssured.value || 0.00
    },
    remainderBeneficiary() {
      const remainderBeneficiary = this.inputs.find(input => input.slug === 'remainer_assured');
      return remainderBeneficiary && remainderBeneficiary.value || 0.00
    },
    daysOfHospitalization() {
      const hospitalDailyRent = this.inputs.find(fields => fields.slug === 'days_of_hospitalization_stay')
      return hospitalDailyRent && hospitalDailyRent.value || null
    },
    amountForHospitalization() {
      const hospitalDailyRent = this.inputs.find(fields => fields.slug === 'amount_for_hospitalization')
      return hospitalDailyRent && hospitalDailyRent.value || null
    },
    hospitalDailyRent() {
      return `${this.daysOfHospitalization}|${this.amountForHospitalization}`
    },
    insuranceClaimType() {
      const insuranceClaimType = this.inputs.find(fields => fields.slug == 'insurance_claim_type')
      return insuranceClaimType && insuranceClaimType.value || null;
    },
    requestedReimbursementAmount() {
      const requestedReimbursementAmount = this.inputs.find(fields => fields.slug == 'requested_reimbursement_amount')
      return requestedReimbursementAmount && requestedReimbursementAmount.value || 0.00;
    },
    isReadOnly() {//TO-DO pending add a new property ReadOnly in the fields table
      return (this.field.slug === 'sum_assured' && ((this.sponsorSlug === 'mx-findep' && this.currentForm !== 'mx-findep-permanent-disability') || this.sponsorSlug === 'mx-aef'))
        || (this.field.slug === 'requested_reimbursement_amount' && this.sponsorSlug === 'mx-aef')
        || (this.field.slug === 'remainder_beneficiary' && (this.currentForm === 'mx-findep-credit-card-balance' || this.currentForm === 'mx-findep-permanent-disability' || this.currentForm === 'mx-aef-credit-card-balance' || this.currentForm === 'mx-aef-permanent-disability'))
        || (this.field.slug === 'amount_covered' && (this.currentForm === 'mx-findep-ppi' || this.currentForm === 'mx-aef-ppi'))
        || (this.field.slug === 'covered_amount'  && (this.sponsorSlug === 'mx-findep' || this.sponsorSlug === 'mx-aef'))
        || (this.field.slug === 'amount_for_hospitalization' && this.validateSponsor)
        || (this.field.slug === 'max_sum_assured' && this.sponsorSlug === 'mx-aef' && ( this.currentForm == 'mx-aef-credit-card-balance' || this.currentForm == 'mx-aef-funeral-assistance' || this.currentForm == 'mx-aef-permanent-disability'))
    },
    validateSponsor() {
      return this.sponsorSlug === 'mx-findep' || this.sponsorSlug === 'mx-aef'
    }
  },
  watch: {
    defaultValue() {
      if (this.field.defaultValue) {
        this.fieldValue = this.formattedAmount(this.field.defaultValue);
        this.changeInput(this.fieldValue)
      } else {
        this.fieldValue = null
        this.changeInput(null)
      }
    },
    modules() {
      const modules = this.modules;
      if (modules && this.field.slug === 'max_sum_assured' && this.sponsorSlug === 'mx-aef' && this.currentCoverage.key != 'mx-aef-funeral-assistance') {
        const moduleParsed = parseFloat(modules) || 0
        const sumAssuredParsed = parseFloat(this.sumAssured) || 0.00
        const maxSumAssured =  moduleParsed * sumAssuredParsed;
        this.fieldValue = this.formattedAmount(maxSumAssured);
        this.changeInput(this.fieldValue);
      } else if (modules && this.field.slug === 'max_sum_assured' && this.sponsorSlug === 'mx-aef' && this.currentCoverage.key == 'mx-aef-funeral-assistance') {
        const moduleParsed = parseFloat(modules) || 0
        const requestedReimbursementAmountParsed = parseFloat(this.requestedReimbursementAmount) || 0.00
        const maxSumAssured =  moduleParsed * requestedReimbursementAmountParsed;
        this.fieldValue = this.formattedAmount(maxSumAssured);
        this.changeInput(this.fieldValue);
      } else if (modules && this.field.slug === 'remainder_beneficiary' && this.sponsorSlug == 'mx-aef') {
        this.updateRemainderBeneficiaryInput();
      }
    },
    sumAssured() {
      const sumAssured = this.sumAssured;
      const maxSumAssured = this.maxSumAssured;
      const outstandingBalance = this.outstandingBalance;
      this.maxCustomError.error = false;
      this.maxCustomError.message = "";
      const isOutstandingBalanceGreaterThanSumAssured = parseFloat(outstandingBalance.toString().replace(/,/g, '')) > parseFloat(sumAssured.toString().replace(/,/g, ''));
      const isOutstandingBalanceGreaterThanMaxSumAssured = parseFloat(outstandingBalance.toString().replace(/,/g, '')) > parseFloat(maxSumAssured.toString().replace(/,/g, ''));

      if (sumAssured && this.field.slug === 'max_sum_assured' && this.sponsorSlug == 'mx-aef') {
        const moduleParsed = parseFloat(this.modules) || 0
        const sumAssuredParsed = parseFloat(sumAssured) || 0.00
        const maxSumAssured =  moduleParsed * sumAssuredParsed;
        this.fieldValue = this.formattedAmount(maxSumAssured).toFixed(2);
        this.changeInput(this.fieldValue);
      }

      if (sumAssured && this.field.slug === 'outstanding_balance' && this.sponsorSlug == 'mx-aef') {
        this.maxCustomError.error = isOutstandingBalanceGreaterThanMaxSumAssured;
        this.maxCustomError.message = isOutstandingBalanceGreaterThanMaxSumAssured ? this.$tc('errors.inputValidations.outstandingBalanceGreaterThanMaxSumAssured', maxSumAssured) : "";
      }

      if (this.maxSumAssured && this.field.slug === 'remainder_beneficiary' && this.sponsorSlug == 'mx-aef') {
        this.updateRemainderBeneficiaryInput();
      }

      if (sumAssured && this.field.slug === 'outstanding_balance' && this.sponsorSlug == 'mx-findep') {
        this.field.max = sumAssured;
        this.$refs.fieldValidationForm.resetValidation();
        this.maxCustomError.error = isOutstandingBalanceGreaterThanSumAssured;
        this.maxCustomError.message = isOutstandingBalanceGreaterThanSumAssured ? this.$tc('errors.inputValidations.outstandingBalanceGreaterThanSumAssured', sumAssured) : "";
      }

      if (this.outstandingBalance && this.field.slug === 'remainder_beneficiary' && this.sponsorSlug === 'mx-findep') {
        this.updateRemainderBeneficiaryInput();
      }
    },
    maxSumAssured() {
      const maxSumAssured = this.maxSumAssured;
      const outstandingBalance = this.outstandingBalance;
      const isOutstandingBalanceGreaterThanMaxSumAssured = parseFloat(outstandingBalance.toString().replace(/,/g, '')) > parseFloat(maxSumAssured.toString().replace(/,/g, ''));

      if (maxSumAssured && this.field.slug === 'outstanding_balance' && this.sponsorSlug == 'mx-aef') {
        this.field.max = maxSumAssured;
        this.$refs.fieldValidationForm.resetValidation();
        this.maxCustomError.error = isOutstandingBalanceGreaterThanMaxSumAssured;
        this.maxCustomError.message = isOutstandingBalanceGreaterThanMaxSumAssured ? this.$tc('errors.inputValidations.outstandingBalanceGreaterThanMaxSumAssured', maxSumAssured) : "";
      }
     },
    outstandingBalance() {
      const outstandingBalance = this.outstandingBalance;
      const sumAssured = this.sumAssured;
      const maxSumAssured = this.maxSumAssured;
      this.maxCustomError.error = false;
      this.maxCustomError.message = "";
      const isOutstandingBalanceGreaterThanSumAssured = parseFloat(outstandingBalance.toString().replace(/,/g, '')) > parseFloat(sumAssured.toString().replace(/,/g, ''));
      const isOutstandingBalanceGreaterThanMaxSumAssured = parseFloat(outstandingBalance.toString().replace(/,/g, '')) > parseFloat(maxSumAssured.toString().replace(/,/g, ''));

      if (sumAssured && this.field.slug === 'outstanding_balance') {
        if (this.sponsorSlug == 'mx-findep') {
          this.field.max = sumAssured;
          this.$refs.fieldValidationForm.resetValidation();
          this.maxCustomError.error = isOutstandingBalanceGreaterThanSumAssured;
          this.maxCustomError.message = isOutstandingBalanceGreaterThanSumAssured ? this.$tc('errors.inputValidations.outstandingBalanceGreaterThanSumAssured', sumAssured) : "";
        }

        if (this.sponsorSlug == 'mx-aef') {
          this.field.max = maxSumAssured;
          this.$refs.fieldValidationForm.resetValidation();
          this.maxCustomError.error = isOutstandingBalanceGreaterThanMaxSumAssured;
          this.maxCustomError.message = isOutstandingBalanceGreaterThanMaxSumAssured ? this.$tc('errors.inputValidations.outstandingBalanceGreaterThanSumAssured', maxSumAssured) : "";
        }
      }

      if (outstandingBalance && this.field.slug === 'remainder_beneficiary'  && (this.sponsorSlug === 'mx-findep' || this.sponsorSlug == 'mx-aef')) {
        this.updateRemainderBeneficiaryInput();
      }

    },
    hospitalDailyRent() {
      const daysOfhospitalization = this.daysOfHospitalization
      const amountForHospitalization = this.amountForHospitalization
      if (daysOfhospitalization && amountForHospitalization && this.field.slug === 'amount_covered' && this.validateSponsor) {
         const daysParsed = parseFloat(daysOfhospitalization)
         const amountPrsed = parseFloat(amountForHospitalization)
         const amountCovered = daysParsed * amountPrsed
         this.fieldValue = this.formattedAmount(amountCovered)
         this.changeInput(this.fieldValue)
      }
    },
    insuranceClaimType() {
      const insuranceClaimType = this.insuranceClaimType;
      if(insuranceClaimType != 'HOSPITAL_DAILY_RENT') {
        this.removeInput('amount_for_hospitalization');
        this.removeInput('days_of_hospitalization_stay');
      }
    },
    requestedReimbursementAmount() {
      const modules = this.modules;
      const requestedReimbursementAmount= this.requestedReimbursementAmount;
      if (modules && this.field.slug === 'max_sum_assured' && this.sponsorSlug === 'mx-aef') {
        this.fieldValue = this.formattedAmount(modules * requestedReimbursementAmount)
        this.changeInput(this.fieldValue)
      }
    }
  }
}
</script>
<style lang="css">
.offset-6-right {
     margin-right: 50%;
}
</style>

